import { UserTypeEnum } from 'src/types/enums';
import { ResponseDataWithPagination } from 'src/types/http';
import { GenerateUsersExportParams, UserExport } from 'src/types/users';
import http from '.';

/**
 * types:
 *  * 1 - admin
 *  * 2 - regular
 *  * 3 - fake
 *  * 4 - in progress (pending fake user)
 */
export const getUsers = (params): Promise<any> => {
  return http.get('/users', { params }).then((res) => res.data);
};

export const getFakeUsers = (params): Promise<any> => {
  return http.get('/fake-users', { params }).then((res) => res.data);
};

export const getFakeUsersController = (params, controller): Promise<any> => {
  return http.get('/fake-users', { params, signal: controller.signal }).then((res) => res.data);
};

export const getInProgress = (params): Promise<any> => {
  return http.get('/fake-users/in-progress', { params }).then((res) => res.data);
};

export const getInProgressController = (params, controller): Promise<any> => {
  return http
    .get('/fake-users/in-progress', { params, signal: controller.signal })
    .then((res) => res.data);
};

export const getRecipients = (
  data
): Promise<{
  message: string;
  status: number;
  data: {
    potential_chats_count: number;
    existing_chats_count: number;
  };
}> => {
  return http.post('/messages/users/count', data).then((res) => res.data);
};

export const getUsersStats = (params): Promise<any> => {
  return http.get('/stats/users', { params }).then((res) => res);
};

export const getUser = (id): Promise<any> => {
  return http.get(`/users/${id}`).then((res) => res.data);
};

// Create fake user
export const createUser = (data): Promise<any> => {
  return http.post('/fake-users', { ...data, type_id: UserTypeEnum.IN_PROGRESS });
};

export const getFakeUser = (id, params): Promise<any> => {
  return http.get(`/fake-users/${id}`, { params }).then((res) => res.data);
};

export const updateUser = (id, data): Promise<any> => {
  return http.post(`/users/${id}`, data);
};

export const updateFakeUser = (id, data): Promise<any> => {
  return http.patch(`/fake-users/${id}`, data);
};

export const deleteUser = (id): Promise<any> => {
  return http.delete(`/users/${id}`);
};

export const deleteFakeUser = (id): Promise<any> => {
  return http.delete(`/fake-users/${id}`);
};

export const getUserActivity = (id, page): Promise<any> => {
  return http.get(`/users/${id}/activity`, { params: { page } }).then((res) => res.data);
};

export const updateAnswers = (userId, data): Promise<any> => {
  return http.post(`/users/${userId}/questions/answers`, data);
};

export const toggleInterest = (userId, interestId): Promise<any> => {
  return http.post(`/users/${userId}/interests`, { id: interestId });
};

export const toggleCharacter = (userId, characterId): Promise<any> => {
  return http.post(`/users/${userId}/characters`, { id: characterId });
};

export const changeCredits = (data): Promise<any> => {
  return http.post('/users/credits', data);
};

export const getUserTransactions = (userId, page): Promise<any> => {
  return http.get(`/coins/${userId}/transactions`, { params: { page } });
};

export const getUserPostback = (userId, page): Promise<any> => {
  return http.get(`/users/${userId}/postback`, { params: { page } });
};

export const verifyUser = (id): Promise<any> => {
  return http.post(`/users/verify/${id}`, { id });
};

export const changeUserPassword = (userId, password) => {
  return http.patch(`users/${userId}/password`, { password });
};

// different endpoint
export const getBannedUsers = (data): Promise<any> => {
  return http.get('/profile/banned', { params: data }).then((res) => res.data);
};

export const banUser = (user_ids): Promise<any> => {
  return http.post('/profile/ban', { user_ids });
};

export const unbanUser = (user_ids): Promise<any> => {
  return http.post('/profile/unban', { user_ids });
};

export const findUserByEmail = (email): Promise<any> => {
  return http.get('/profile/banned', { params: { email } });
};

export const getDeletedUsers = (data): Promise<any> => {
  return http.get('/profile/deleted', { params: data }).then((res) => res.data);
};

export const restoreProfile = (id): Promise<any> => {
  return http.get(`/profile/${id}/restore`).then((res) => res.data);
};

export const getReportedUsers = (data): Promise<any> => {
  return http.get('/profiles/reported', { params: data }).then((res) => res.data);
};

export const removeFromReportedList = (id): Promise<any> => {
  return http.delete(`/profile/unreport/${id}`);
};

export const getMultiAccounts = (params): Promise<any> => {
  return http.get('/multiple/accounts', { params }).then((res) => res.data);
};

export const deleteAndBanEmailOrIP = (id, type): Promise<any> => {
  return http.post('/profile/delete', {
    user_id: id,
    block: type,
  });
};

export const deleteAndBanIP = (id): Promise<any> => {
  return http.delete(`/profile/delete/${id}/ban/ip`);
};

export const deleteAndBanEmail = (id): Promise<any> => {
  return http.delete(`/profile/delete/${id}/ban/mail`);
};

export const getDeletedCSV = (): Promise<any> => {
  return http.get('/profile/deleted/csv/download');
};

export const generateUsersExports = (params: GenerateUsersExportParams) => {
  if (typeof params.gender === 'string') {
    (params as any).gender = null;
  }
  return http.get<[]>('/users/exports/generate', { params });
};

export const countUsersExports = (params: GenerateUsersExportParams) => {
  if (typeof params.gender === 'string') {
    (params as any).gender = null;
  }
  return http.get<{ count: number }>('/users/exports/count', { params });
};

export const getUsersExports = (params: { page: number }) => {
  return http.get<ResponseDataWithPagination<UserExport[]>>('/users/exports', { params });
};
