// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import {
  getFakeUsers,
  getInProgress,
  deleteFakeUser,
  getUsersStats,
  getFakeUsersController,
  getInProgressController,
} from 'src/endpoints/users';
import formatFilterDate from 'src/utils/formatFilterDate';
import { GenderEnum } from 'src/types/enums';
import { UserFilters } from 'src/types/users';
import omitEmptyValues from 'src/utils/omitEmptyValues';

class FakeUsers {
  @observable isLoading = false;

  @observable type = null;

  @observable users = [];

  @observable isLoadingStats = false;

  @observable userStats = <any>{};

  @observable selectedUsers = [];

  @observable filters: Partial<UserFilters> = {};

  @observable meta: any;

  @observable links: any;

  @action setType(type) {
    this.type = type;
  }

  @action load(showLoader = true) {
    if (showLoader) {
      this.isLoading = true;
    }
    if (this.type != null) {
      if (this.type[0] === '3') {
        return (
          this.type &&
          getFakeUsers({
            type: this.type,
            ...omitEmptyValues(this.filters),
          })
            .then((res) => {
              this.users = res.data;
              this.isLoading = false;
              this.meta = res.meta;
              this.links = res.links;
            })
            .catch(() => {
              this.isLoading = false;
              // toast.error(err);
            })
        );
      }
    }
    return (
      this.type &&
      getInProgress({
        type: this.type,
        ...omitEmptyValues(this.filters),
      })
        .then((res) => {
          this.users = res.data;
          this.isLoading = false;
          this.meta = res.meta;
          this.links = res.links;
        })
        .catch(() => {
          this.isLoading = false;
          // toast.error(err);
        })
    );
  }

  @action async loadStats() {
    this.isLoadingStats = true;
    try {
      const { data } = await getUsersStats({
        type: this.type,
        ...omitEmptyValues(this.filters),
      });
      this.userStats = data;
      this.isLoadingStats = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoadingStats = false;
    }
  }

  @action loadPaginated(showLoader = true, page, controller) {
    if (showLoader) {
      this.isLoading = true;
    }
    if (this.type != null) {
      if (this.type[0] === '3') {
        return (
          this.type &&
          getFakeUsersController(
            {
              type: this.type,
              ...omitEmptyValues(this.filters),
              page,
            },
            controller
          )
            .then((res) => {
              this.users = res.data;
              this.isLoading = false;
              this.meta = res.meta;
              this.links = res.links;
            })
            .catch(() => {
              this.isLoading = false;
              // toast.error(err);
            })
        );
      }
    }
    return (
      this.type &&
      getInProgressController(
        {
          type: this.type,
          ...omitEmptyValues(this.filters),
          page,
        },
        controller
      )
        .then((res) => {
          this.users = res.data;
          this.isLoading = false;
          this.meta = res.meta;
          this.links = res.links;
        })
        .catch(() => {
          this.isLoading = false;
          // toast.error(err);
        })
    );
  }

  @action deleteUser(id) {
    deleteFakeUser(id).then((res) => {
      if (res.status === 200) {
        toast.success('Fake user deleted.');
        this.load(false);
        this.loadStats();
      } else {
        toast.error('Failed to delete fake user.');
      }
    });
  }

  @action setFilter(key: keyof UserFilters, value) {
    this.filters[key] = value;
  }

  @action setRegisteredDateFilter(selection) {
    if (selection.startDate !== selection.endDate) {
      this.filters.registerDate = `${formatFilterDate(selection.startDate)}_${formatFilterDate(
        selection.endDate
      )}`;
    }
  }

  @action setSelectedUsers(userId) {
    this.selectedUsers = userId;
  }

  @action removeFilter(key) {
    delete this.filters[key];
  }

  @action resetFilters() {
    this.filters = {};
    this.load();
  }

  @action resetAll() {
    this.type = null;
    this.users = [];
    this.selectedUsers = [];
    this.filters = {};
  }

  usersByGender(gender) {
    return (
      this.users.length &&
      filter(this.users, (user) => (user as any).gender === GenderEnum[gender.toUpperCase()]).length
    );
  }

  @computed get hasFilters() {
    return !isEmpty(this.filters);
  }
}

export default new FakeUsers();
