import http from '../index';

export const getProfileQuestions = (): Promise<any> => {
  return http.get('/questions').then((res) => res.data);
};

export const createProfileQuestion = (body): Promise<any> => {
  return http.post('/questions', body);
};

export const updateProfileQuestion = (id, body): Promise<any> => {
  return http.patch(`/questions/${id}`, body);
};

export const deleteProfileQuestion = (id): Promise<any> => {
  return http.delete(`/questions/${id}`);
};

export const createProfileAnswer = (body): Promise<any> => {
  return http.post('/question/answers', body);
};

export const updateProfileAnswer = (id, body): Promise<any> => {
  return http.patch(`/question/answers/${id}`, body);
};

export const deleteProfileAnswer = (id): Promise<any> => {
  return http.delete(`/question/answers/${id}`);
};
