export enum UserTypeEnum {
  ADMIN = 1,
  REGULAR = 2,
  FAKE = 3,
  IN_PROGRESS = 4,
}

export enum GenderEnum {
  ALL = 'all',
  NONE = 'choose gender',
  MALE = 1,
  FEMALE = 2,
}

export enum CountryEnum {
  ALL = 'all',
  AUSTRIA = 1,
  GERMANY = 2,
  SWITZERLAND = 3,
}

export enum SenderTypeEnum {
  RANDOM_FAKE_USER = 1,
  FAKE_USER = 2,
  SUPPORT = 3,
  EMPTY = 4,
}

export enum StaticTypeEnum {
  DAILY_STATIC = 1,
  PROLONGED_STATIC = 2,
}

export enum EmailCategories {
  CHAT_NOTIFICATIONS = 1,
  MASS_PM = 2,
  VERIFY = 3,
  WELCOME = 4,
  RESET_PASS = 5,
  COINS = 6,
  NOTIFICATIONS = 7,
}

export enum ImageTypeEnum {
  REAL = '0',
  FAKE = '1',
}

export enum TimeOfDayEnum {
  NONE = 'chose time of day',
  MORNING = 1,
  AFTERNOON = 2,
  NIGHT = 3,
}

export enum PaymentGatewayEnum {
  CreditCard = 1,
  Paysafecard = 2,
  Call2Pay = 3,
  Sofort = 4,
  SofortMobilePay = 5,
  Vorkasse = 6,
  Stripe = 7,
  PayPal = 8,
  GooglePay = 9,
}

export enum CreditTypeEnum {
  FREE = 0,
  PAID = 1,
}
