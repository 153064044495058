// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { getBannedUsers, unbanUser, findUserByEmail } from 'src/endpoints/users';

class BannedUsers {
  @observable bannedUsers = [];

  @observable selectedUsers = [];

  @observable isLoading = false;

  @observable meta: any;

  @action async getPaginated(page) {
    this.isLoading = true;
    try {
      const { data, meta } = await getBannedUsers({ page });
      this.bannedUsers = data;
      this.meta = meta;
      this.isLoading = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoading = false;
    }
  }

  @action async unbanUser(users) {
    try {
      await unbanUser(users);
      this.setSelected([]);
      await this.getPaginated(1);
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action async findUserByEmail(email) {
    try {
      const { data } = await findUserByEmail(email);
      this.bannedUsers = data.data;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action setSelected(ids) {
    this.selectedUsers = ids;
  }
}

export default new BannedUsers();
