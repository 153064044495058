import http from '../index';

export const getRooms = async (id): Promise<any> => {
  return http.get(`/mods/${id}/rooms`).then((res) => res.data);
};

export const getRecentRooms = async (): Promise<any> => {
  return http.get('/mods/most-recent-rooms').then((res) => res.data);
};

export const getRoom = async (id, room, data): Promise<any> => {
  return http.get(`/mods/${id}/rooms/${room}`, data).then((res) => res.data);
};
