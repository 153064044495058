import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import conf from '../config';

/**
 * Creates new instance of axios with baseUrl pre-set.
 */
const instance = axios.create({ baseURL: conf.apiUrl });

/**
 * Create an interceptor for requests that attaches auth
 * token in every request sent to the API.
 */
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth_token');

  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }

  return config;
});

/**
 * Catch error messages from BE and show them in toast notification
 */
instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status === 401) {
      window.location.replace('/login');
      return;
    }
    if (err?.response?.data?.errors) {
      const errors: string[] = [];
      Object.values(err?.response?.data?.errors).forEach((value) => {
        errors.push(String(value));
      });
      toast.error(
        <div>
          The given data was invalid:
          <ul>
            {errors.map((error: string) => (
              <li>{error}</li>
            ))}
          </ul>
        </div>,
        { position: toast.POSITION.TOP_RIGHT }
      );
    } else {
      toast.error(err?.response?.data?.message);
    }
    throw err;
  }
);

export default instance;
