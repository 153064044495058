import http from '../index';

export const getOnlineMods = async (params): Promise<any> => {
  return http.get('/mods/mods-online', { params }).then((res) => res.data);
};

export const getOpenMessage = async (params, controller): Promise<any> => {
  return http
    .get('/mods/open-rooms', { signal: controller.signal, params })
    .then((res) => res.data);
};

export const getASAMessages = async (params, controller): Promise<any> => {
  return http
    .get('/mods/closed-rooms', { signal: controller.signal, params })
    .then((res) => res.data);
};

export const getChatMessages = async (roomId, data): Promise<any> => {
  return http.get(`room/${roomId}`, data).then((res) => res.data);
};
