import {
  TeamData,
  TeamCostsRequest,
  Team,
  CreateTeamResponse,
  TeamEarningMonthResponse,
  TeamEarningMonthFIleResponse,
} from 'src/types/teams';
import { AxiosResponse } from 'axios';
import http from '../index';

export const getTeams = async (page): Promise<any> => {
  return http.get('/teams', { params: { page } }).then((res) => res);
};

export const getTeamsSelect = async (params): Promise<any> => {
  return http.get('/teams', { params }).then((res) => res);
};

export const getTeamLeaders = async (data): Promise<any> => {
  return http.get('/teams/leader', { params: data }).then((res) => res);
};

export const getTeam = async (id): Promise<any> => {
  return http.get(`/teams/${id}`).then((res) => res.data);
};

export const getTeamEarning = async (id, data): Promise<any> => {
  return http.get(`/teams/${id}/earning`, data).then((res) => res.data);
};

export const getTeamEarningMonth = async (id: number, data): Promise<TeamEarningMonthResponse> => {
  return http
    .get<any, AxiosResponse<TeamEarningMonthResponse>>(`teams/${id}/earning-month`, data)
    .then((res) => res.data);
};

export const getTeamEarningMonthFile = async (
  id: number
): Promise<TeamEarningMonthFIleResponse> => {
  return http
    .get<any, AxiosResponse<TeamEarningMonthFIleResponse>>(`teams/${id}/earning-month-file`)
    .then((res) => res.data);
};

export const getTeamCosts = async (id): Promise<any> => {
  return http.get(`/teams/${id}/costs`).then((res) => res.data);
};

export const createTeam = async (data: TeamData): Promise<any> => {
  const team: Team = {
    name: data.name,
    user_id: data.user_id,
    id: data.id,
    agency_id: data.agency_id,
  };
  return http
    .post<Team, AxiosResponse<{ data: CreateTeamResponse[]; message: string; status: number }>>(
      '/teams',
      team
    )
    .then((res) => {
      // TODO Uncomment when team prices are done on BE
      // const responseData: CreateTeamResponse = res.data.data[0];
      // if (res.status === 200 && responseData.id) {
      //   const createTeamCostsRequest: TeamCostsRequest = {
      //     team_id: responseData.id ?? 0,
      //     out_open_cost: data.out_open_cost,
      //     out_asa_cost: data.out_asa_cost,
      //     in_open_cost: data.in_open_cost,
      //     in_asa_cost: data.in_asa_cost,
      //     free_out_open_cost: data.free_out_open_cost,
      //     free_in_open_cost: data.free_in_open_cost,
      //     free_in_asa_cost: data.free_in_asa_cost,
      //   };
      //   return createTeamCost(createTeamCostsRequest);
      // }
      return res;
    });
};

export const createTeamCost = async (data: TeamCostsRequest): Promise<any> => {
  return http.post('/teams/costs', data);
};

export const updateTeam = async (id, data: Partial<TeamData>): Promise<any> => {
  const team: Team = {
    name: data.name ?? '',
    user_id: data.user_id,
    id: data.id,
    agency_id: data.agency_id,
  };
  return http.put(`/teams/${id}`, team).then((res) => {
    // TODO Uncomment when team prices are done on BE
    // if (res.status === 200 && data.in_asa_cost) {
    //   const createTeamCostsRequest: TeamCostsRequest = {
    //     team_id: id,
    //     out_open_cost: data.out_open_cost ?? '',
    //     out_asa_cost: data.out_asa_cost ?? '',
    //     in_open_cost: data.in_open_cost ?? '',
    //     in_asa_cost: data.in_asa_cost ?? '',
    //     free_out_open_cost: data.free_out_open_cost ?? '',
    //     free_in_open_cost: data.free_in_open_cost ?? '',
    //     free_in_asa_cost: data.free_in_asa_cost ?? '',
    //   };
    //   return updateTeamCost(createTeamCostsRequest);
    // }
    return res;
  });
};

export const updateTeamCost = async (data: TeamCostsRequest): Promise<any> => {
  return http.put(`/teams/${data.team_id}/costs`, {
    ...data,
    free_out_asa_cost: '0', // FIXME: remove this line when backend is fixed
  });
};

export const deleteTeam = async (id): Promise<any> => {
  return http.delete(`/teams/${id}`);
};
