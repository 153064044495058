import { createContext, useContext } from 'react';

import auth from './Auth';
import bannedIPs from './BannedIPs';
import bannedUsers from './BannedUsers';
import deletedUsers from './DeletedUsers';
import fakeUsers from './FakeUsers';
import fakeUserProfile from './FakeUserProfile';
import media from './Media';
import mods from './Mods';
import multiAccounts from './MultiAccounts';
import multiMessages from './MultiMessages';
import emailTemplates from './EmailTemplates';
import profileQuestions from './ProfileQuestions';
import reportedUsers from './ReportedUsers';
import sidebar from './Sidebar';
import teams from './Teams';
import chatStore from './ChatStore';
import users from './Users';
import userMedia from './UserMedia';
import userProfile from './UserProfile';
import dashboardInfo from './Dashboard';
import asa from './ASA';
import campaignMessageStore from './CampaignMessages';
import earningStore from './Earnings';

export const stores = {
  asaStore: asa,
  authStore: auth,
  bannedIPsStore: bannedIPs,
  bannedUsersStore: bannedUsers,
  deletedUsersStore: deletedUsers,
  fakeUsersStore: fakeUsers,
  fakeUserProfileStore: fakeUserProfile,
  mediaStore: media,
  modsStore: mods,
  multiAccountsStore: multiAccounts,
  multiMessagesStore: multiMessages,
  profileQuestionsStore: profileQuestions,
  reportedUsersStore: reportedUsers,
  sidebarStore: sidebar,
  teamsStore: teams,
  usersStore: users,
  userMediaStore: userMedia,
  userProfileStore: userProfile,
  dashboardStore: dashboardInfo,
  campaignMessageStore,
  emailTemplates,
  chatStore,
  earningStore,
};

const storesContext = createContext(stores);

export const useStores = () => useContext(storesContext);
