// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';
import { toast } from 'react-toastify';
import {
  getImages,
  deleteImages,
  setImagesAsProfile,
  updateImageProperty,
} from 'src/endpoints/userMedia';
import { UserMediaStatusEnum, UserMediaFilters } from 'src/types/userMedia';
import omitEmptyValues from 'src/utils/omitEmptyValues';

class UserMedia {
  @observable media = [];

  @observable filters: Partial<UserMediaFilters> = {};

  @observable selectedMedia: Array<string> = [];

  @observable isLoading = false;

  @observable meta: any;

  @action async loadMedia(page) {
    this.isLoading = true;
    try {
      const { data, meta } = await getImages({
        ...omitEmptyValues(this.filters),
        page,
      });
      this.media = data;
      this.meta = meta;
      this.isLoading = false;
    } catch (err) {
      console.log(JSON.stringify(err));
      this.isLoading = false;
    }
  }

  @action deleteMedia(ids: Array<string>) {
    deleteImages(ids)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Media deleted.');
          this.loadMedia(1);
          this.selectedMedia = [];
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action setAsProfile(userId, mediaId) {
    setImagesAsProfile(userId, mediaId)
      .then(() => this.loadMedia(1))
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action updateMediaProperty(mediaIds: Array<string>, property: 'status' | 'erotic', value) {
    updateImageProperty(mediaIds, property, value)
      .then(() => this.loadMedia(1))
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action setSelectedMedia(mediaIds: Array<string>) {
    this.selectedMedia = mediaIds;
  }

  @action approveAllMedia() {
    const mediaIds = this.media
      .filter(({ status }) => status === UserMediaStatusEnum.PENDING)
      .map(({ id }) => id);

    updateImageProperty(mediaIds, 'status', 'approved')
      .then((res) => {
        if (res.status === 200) {
          toast.success('All media approved.');
          this.selectedMedia = [];
          this.loadMedia(1);
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action setFilters(key: keyof UserMediaFilters, value) {
    this.filters[key] = value;
  }

  @action resetFilters() {
    this.filters = {};
  }

  @computed get processedMedia() {
    return this.media.map(({ original_img_url, video, name }, i) => ({
      index: i,
      url: video || original_img_url,
      type: video ? 'video' : 'photo',
      altTag: name,
    }));
  }
}

export default new UserMedia();
