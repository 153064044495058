import http from '../index';

export const getCampaignMessages = async (id, data): Promise<any> => {
  return http.get(`/campaign/${id}/messages`, data).then((res) => res.data);
};

export const getCampaignMessage = async (id): Promise<any> => {
  return http.get(`/campaign/messages/${id}`).then((res) => res.data);
};

export const deleteCampaignMessage = async (id): Promise<any> => {
  return http.delete(`/campaign/messages/${id}`);
};

export const sendCampaignMessages = async (data): Promise<any> => {
  return http.post('/campaign/messages', data);
};
