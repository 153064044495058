// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { action, observable, runInAction, toJS } from 'mobx';
import { getEarningReports } from 'src/endpoints/mods/mods';

class EarningStore {
  @observable isLoading = false;

  @observable earningReports = [] as any;

  @observable meta: any;

  setearningReports = (data) => {
    this.earningReports = data;
  };

  updateEarnings = (data) => {
    if (this.earningReports && toJS(this.earningReports).some((room) => room.id === data.id)) {
      this.earningReports = this.earningReports.map((room) =>
        String(room.id) === String(data.id) ? { ...room, url: data.url } : room
      );
    }
  };

  removeReport = (record) => {
    this.earningReports = this.earningReports.filter((item) => item.id !== record.id);
  };

  getEarning = (data) => {
    if (this.earningReports && toJS(this.earningReports).some((room) => room.id === data)) {
      return toJS(this.earningReports).find((element) => element.id === data).url;
    }
    return null;
  };

  @action clearearningReports() {
    this.setearningReports([]);
  }

  @action geteErningReportsDashboard() {
    getEarningReports({})
      .then((res) => {
        runInAction(() => {
          this.earningReports = res.data;
          this.meta = res.meta;
        });
      })
      .catch(() => {
        // toast.error(err.toString());
      });
  }

  @action loadPaginated(page) {
    getEarningReports({ page })
      .then((res) => {
        runInAction(() => {
          this.earningReports = res.data;
          this.meta = res.meta;
        });
      })
      .catch(() => {
        // toast.error(err.toString());
      });
  }
}

export default new EarningStore();
