// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import { getASA, createASA, updateASA, deleteASA, ASABody, activateASA } from 'src/endpoints/asa';

class ASA {
  @observable asaList = [];

  @observable editing: number | null = null;

  @action setEditing(id) {
    this.editing = id;
  }

  @action load() {
    getASA().then((res) => {
      this.asaList = res.data;
    });
  }

  @action delete(id) {
    return deleteASA(id).then((res) => {
      if (res.status === 200) {
        toast.success('ASA deleted.');
        this.load();
        this.asaList = [];
      }
    });
  }

  @action createASA(body) {
    return createASA(body).then((res) => {
      if (res.status === 200 || 201) {
        toast.success('ASA created.');
        this.load();
        this.asaList = [];
      }
    });
  }

  @action activateASA(id, is_active: boolean) {
    return activateASA(id, is_active).then((res) => {
      if (res.status === 200 || 201) {
        toast.success('ASA activated.');
        this.load();
        this.asaList = [];
      }
    });
  }

  @action updateASA(body: ASABody, id) {
    return updateASA(body, id).then((res) => {
      if (res.status === 200 || 201) {
        toast.success('ASA updated.');
        this.load();
        this.asaList = [];
      }
    });
  }
}

export default new ASA();
