import { EmailCategories } from 'src/types/enums';
import http from '../index';

export const getProviderTypes = (): Promise<any> => {
  return http.get('/email/providers').then((res) => res.data);
};

export const getAllEmailProviders = (type: EmailCategories): Promise<any> => {
  return http.get('/email/settings', { params: { type } }).then((res) => res.data);
};

export const createEmailProvider = (data): Promise<any> => {
  return http.post('/email/settings', data).then((res) => res.data);
};

export const updateEmailProvider = (id, data): Promise<any> => {
  return http.post(`/email/settings/${id}?_method=PATCH`, data).then((res) => res.data);
};

export const deleteEmailProvider = (id): Promise<any> => {
  return http.delete(`/email/settings/${id}`).then((res) => res.data);
};

export const getEmailTemplate = (id): Promise<any> => {
  return http.get(`/email/template/${id}`).then((res) => res.data);
};

export const getEmailTemplates = (): Promise<any> => {
  return http.get('/email/template').then((res) => res);
};

export const updateEmailTemplate = (id, data): Promise<any> => {
  return http.post(`/email/template/${id}?_method=PATCH`, data).then((res) => res.data);
};

export const createEmailTemplate = (data): Promise<any> => {
  return http.post('/email/template', data).then((res) => res);
};

export const deleteEmailTemplate = (id): Promise<any> => {
  return http.delete(`/email/template/${id}`).then((res) => res.data);
};

export const createEmailTest = (data): Promise<any> => {
  return http.post('/email/test', data);
};

export const getCurrentDomain = (): Promise<any> => {
  return http.get('/domains/current').then((res) => res);
};

export const setDomain = (id): Promise<any> => {
  return http.post(`/domains/current/${id}`, { is_current: 1 }).then((res) => res);
};

export const getCurrentIP = (): Promise<any> => {
  return http.get('/domains/current/ipaddress').then((res) => res);
};

export const getAllDomains = (): Promise<any> => {
  return http.get('/domains').then((res) => res);
};

export const getEmailLogs = (params, controller): Promise<any> => {
  return http.get('/email/logs', { params, signal: controller.signal }).then((res) => res.data);
};

export const addDomain = (data): Promise<any> => {
  return http.post('/domains', data).then((res) => res);
};

export const deleteDomain = (id): Promise<any> => {
  return http.delete(`/domains/${id}`).then((res) => res);
};
