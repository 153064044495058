import http from './index';

export const getASA = async (): Promise<any> => {
  return http.get('/asa').then((res) => res.data);
};

export interface ASABody {
  hours?: number;
  minutes?: number;
  time_in?: string;
  unresponded_numb?: number;
}

export const createASA = (data: ASABody): Promise<any> => {
  return http.post('/asa', data).then((res) => res.data);
};

export const updateASA = (data: ASABody, id): Promise<any> => {
  return http.put(`/asa/${id}`, data).then((res) => res.data);
};

export const activateASA = (id, is_active): Promise<any> => {
  return http.put(`/asa/${id}`, { is_active }).then((res) => res.data);
};

export const deleteASA = async (id): Promise<any> => {
  return http.delete(`/asa/${id}`);
};
