import http from '.';

export const getBannedIPs = (): Promise<any> => {
  return http.get('/banned/ips');
};

export const unBanIP = (ips): Promise<any> => {
  return http.post('/unban/ip', { ips });
};

export const banIp = (ip): Promise<any> => {
  return http.post('/profile/unblock', { ip });
};
