// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import {
  getReportedUsers,
  removeFromReportedList,
  deleteAndBanIP,
  deleteAndBanEmail,
} from 'src/endpoints/users';
import { getChatMessages } from 'src/endpoints/rooms/rooms';

class ReportedUsers {
  @observable reportedUsers = [] as any;

  @observable currentChatMessages = [] as any;

  @observable isLoading = false;

  @observable meta: any;

  setChatMessages = (data) => {
    this.currentChatMessages = data;
  };

  @action async getChat(chatId) {
    await getChatMessages(chatId, {}).then(({ data }) => {
      this.setChatMessages(data);
    });
  }

  @action async getPaginated(page) {
    this.isLoading = true;
    try {
      const { data, meta } = await getReportedUsers({ page });
      this.reportedUsers = data;
      this.meta = meta;
      this.isLoading = false;
    } catch (err) {
      console.log(JSON.stringify(err));
      this.isLoading = false;
    }
  }

  @action async postRemoveFromReportedList(id) {
    try {
      await removeFromReportedList(id);
      await this.getPaginated(1);
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  }

  @action async postDeleteAndBanIP(id) {
    try {
      await deleteAndBanIP(id);
      await this.getPaginated(1);
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  }

  @action async postDeleteAndBanEmail(id) {
    try {
      await deleteAndBanEmail(id);
      await this.getPaginated(1);
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  }
}

export default new ReportedUsers();
