// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { getTeams, createTeam, updateTeam, deleteTeam } from 'src/endpoints/mods/teams';

class Teams {
  @observable data = [];

  @observable isLoading = false;

  @observable meta: any;

  @action load(page) {
    this.isLoading = true;
    return getTeams(page)
      .then((res) => {
        if (res.status === 200) {
          this.isLoading = false;
          this.data = res.data.data;
          this.meta = res.data.meta;
        }
      })
      .catch((err) => {
        this.isLoading = false;
        console.log(JSON.stringify(err));
      });
  }

  @action create(data) {
    return createTeam(data)
      .then((res) => {
        if (res.status === 200) {
          this.load(1);
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  }

  @action update(id, data) {
    return updateTeam(id, data)
      .then((res) => {
        if (res.status === 200) {
          this.load(this.meta.page);
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  }

  @action delete(id) {
    return deleteTeam(id)
      .then((res) => {
        if (res.status === 200) {
          this.load(this.meta.page);
        }
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
      });
  }
}

export default new Teams();
