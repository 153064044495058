// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';

class Sidebar {
  @observable sidebarShow: boolean | string;

  constructor() {
    this.sidebarShow = 'responsive';
  }

  @action setSidebarShow(val) {
    this.sidebarShow = val;
  }
}

export default new Sidebar();
