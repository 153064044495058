import http from '../index';

export const getModSettings = async (): Promise<any> => {
  return http.get('/mods/settings').then((res) => res);
};

export const getModSetting = async (id): Promise<any> => {
  return http.get(`/mods/settings/${id}`).then((res) => res);
};

export const createSetting = async (data): Promise<any> => {
  return http.post('/mods/settings', data);
};

export const updateSetting = async (id, data): Promise<any> => {
  return http.put(`/mods/settings/${id}`, data);
};

export const deleteSetting = async (id): Promise<any> => {
  return http.delete(`/mods/settings/${id}`);
};
