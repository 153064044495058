import http from '.';

export const getRealImages = (): Promise<any> => {
  return http.get('/images');
};

export const getImagesByFilter = (params): Promise<any> => {
  return http.get('/images', { params });
};

export const deleteImages = (image_ids): Promise<any> => {
  return http.post('/image/delete', { image_ids });
};
