// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import { CampaignMessage } from 'src/types/messages';
import { sendCampaignMessages } from 'src/endpoints/messages/campaign';

const initialMessage: CampaignMessage = {
  subject: '',
  message: '',
  image: '',
  send_after: {
    days: '0',
    hours: '0',
    minutes: '0',
  },
  openChat: '1',
};

class CampaignMessages {
  @observable campaignId;

  @observable messages = [{ ...initialMessage }];

  @action setCampaign = (id) => {
    this.campaignId = id;
  };

  @action addTab() {
    this.messages = [...this.messages, { ...initialMessage }];
  }

  @action closeTab(index) {
    const newMessages = [...this.messages];
    newMessages.splice(index, 1);
    this.messages = newMessages;
  }

  @action updateMessageSendAfter(messageIndex, key: keyof CampaignMessage, innerKey, value) {
    const newMessages = [...this.messages];
    newMessages[messageIndex][key][innerKey] = value;
    this.messages = newMessages;
  }

  @action updateMessage(messageIndex, key: keyof CampaignMessage, value) {
    const newMessages = [...this.messages];
    newMessages[messageIndex][key] = value;
    this.messages = newMessages;
  }

  @action updateTabMessage(messageIndex, value) {
    const newMessages = [...this.messages];
    newMessages[messageIndex] = value;
    this.messages = newMessages;
  }

  @action async sendMessages() {
    try {
      const formData = new FormData();
      this.messages.forEach(({ subject, message, image, send_after, openChat }, i) => {
        formData.append(`messages[${i + 1}][subject]`, subject);
        formData.append(`messages[${i + 1}][message]`, message);
        formData.append(`messages[${i + 1}][open_chat]`, openChat);
        if (image) {
          formData.append(`messages[${i + 1}][image]`, image);
        }
        formData.append(`messages[${i + 1}][send_after][days]`, send_after.days);
        formData.append(`messages[${i + 1}][send_after][hours]`, send_after.hours);
        formData.append(`messages[${i + 1}][send_after][minutes]`, send_after.minutes);
      });
      formData.append('campaign_id', this.campaignId);

      await sendCampaignMessages(formData).then((res) => {
        if (res.status === 200) {
          toast.success('Messages saved!');
        } else {
          toast.error('Failed to save messages.');
        }
      });
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action resetState() {
    this.messages = [{ ...initialMessage }];
  }
}

export default new CampaignMessages();
