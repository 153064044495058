import http from '../index';

export const getMultiMessages = async (data): Promise<any> => {
  return http.get('/multi/messages', data).then((res) => res.data);
};

export const getMultiMessage = async (id): Promise<any> => {
  return http.get(`/multi/messages/${id}`).then((res) => res.data);
};

export const deleteMultiMessage = async (id): Promise<any> => {
  return http.delete(`/multi/messages/${id}`);
};

export const sendMultiMessages = async (data): Promise<any> => {
  return http.post('/multi/messages', data);
};
