// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { EmailTemplate } from 'src/types/emails';
import { updateEmailTemplate, getEmailTemplates } from 'src/endpoints/settings/emailSettings';

class MultiMessages {
  @observable initial: Array<EmailTemplate> = [];

  @observable messages: Array<EmailTemplate> = [];

  @action load() {
    getEmailTemplates()
      .then((res) => {
        if (res.data) {
          this.initial = res.data.reverse();
          this.messages = res.data;
        }
      })
      .catch(() => {
        // toast.error(JSON.stringify(err));
      });
  }

  @action updateTemplate(messageIndex, key: any, value) {
    const newMessages = [...this.messages];
    if (_.isEqual(key, 'template')) {
      newMessages[messageIndex][key]['text/html'] = value;
    } else if (_.isEqual(key, 'text')) {
      newMessages[messageIndex].template['text/plain'] = value;
    } else {
      newMessages[messageIndex][key] = value;
    }
    this.messages = newMessages;
  }

  @action update(index) {
    const initial = this.initial[index];
    const template = this.messages[index];
    if (!_.isEqual(initial, template)) {
      const payload = <any>{};
      if (!_.isEqual(initial.name, template.name)) {
        payload.name = template.name;
      }
      if (!_.isEqual(initial.template, template.template)) {
        payload.template = template.template;
      }
      updateEmailTemplate(template.id, payload).then((res) => {
        if (res.status === (200 || 201)) {
          toast.success('Page updated');
          this.load();
        }
      });
    }
  }
}

export default new MultiMessages();
