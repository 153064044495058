// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { getChats, getChat } from 'src/endpoints/chats';
import { getRoom } from 'src/endpoints/mods/liveDialogue';

class ChatStore {
  @observable conversations: any[] = [];

  @observable selectedChatId;

  @observable selectedChat = <any>[];

  @observable roomData = <any>{};

  @observable isLoadingConversations = false;

  @observable isLoadingMessages = true;

  @observable conversationsMeta: any;

  @observable messagesMeta: any;

  @action async getConversations(userId) {
    this.isLoadingConversations = true;
    this.conversations = [];
    this.selectedChat = [];
    this.selectedChatId = null;
    try {
      const res = await getChats(userId);

      if (Array.isArray(res)) {
        return;
      }

      if (res.data) {
        this.conversations = res.data;
        if (res.data.length) {
          this.getMessages(res.data[0].id);
        }
      }

      if (res.meta) {
        this.conversationsMeta = res.meta;
      }
    } catch (err) {
      // toast.error(JSON.stringify(err));
    } finally {
      this.isLoadingConversations = false;
    }
  }

  @action async loadMoreConversations(id) {
    try {
      const { data, meta } = await getChats(id, {
        params: { page: this.conversationsMeta.current_page + 1 },
      });
      this.conversations = [...this.conversations, ...data];
      this.conversationsMeta = meta;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action async getMessages(id) {
    if (id === undefined) {
      return;
    }
    this.isLoadingMessages = true;
    this.selectedChatId = null;
    this.selectedChat = [];
    try {
      const { data, meta } = await getChat(id, {});
      this.selectedChat = data;
      this.messagesMeta = meta;
      this.selectedChatId = id;
      this.isLoadingMessages = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoadingMessages = false;
    }
  }

  @action async loadMoreMessages(id) {
    if (id === undefined) {
      return;
    }
    try {
      const { data, meta } = await getChat(id, {
        params: { page: this.messagesMeta.current_page + 1 },
      });
      this.selectedChat = [...this.selectedChat, ...data];
      this.messagesMeta = meta;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action async getMessageLiveDialogue(id, room) {
    if (id === undefined) {
      return;
    }
    this.isLoadingMessages = true;
    this.selectedChatId = null;
    this.selectedChat = [];
    try {
      const { data, messages, meta } = await getRoom(id, room, {});
      this.selectedChat = messages;
      this.messagesMeta = meta;
      this.selectedChatId = id;
      this.roomData = data;
      this.isLoadingMessages = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoadingMessages = false;
    }
  }

  @action async loadMoreLiveDialogue(id, room) {
    if (id === undefined) {
      return;
    }
    try {
      const { messages, meta } = await getRoom(id, room, {
        params: { page: this.messagesMeta.current_page + 1 },
      });
      this.selectedChat = [...messages, ...this.selectedChat];
      this.messagesMeta = meta;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }
}

export default new ChatStore();
