import React from 'react';
import styled from 'styled-components';

const StyledLoader = styled.div`
  margin: auto;
  transform: translateX(calc(-50% + 40px));
  width: 80px;
  height: 80px;
  z-index: 1000;
  & div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background-color: #6b7fff !important;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    &:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      left: 32px;
      animation-delay: -0.12s;
    }
    &:nth-child(3) {
      left: 56px;
      animation-delay: 0;
    }
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
`;

const Loader = () => (
  <StyledLoader>
    <div />
    <div />
    <div />
  </StyledLoader>
);

export default Loader;
