// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { action, observable, computed } from 'mobx';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { login, getLoggedAdminUser } from 'src/endpoints/auth';
import RolesEnum from 'src/types/roles';

class Auth {
  @observable isLoading = false;

  @observable adminInfo: Record<string, any> = {};

  @action login(data) {
    this.isLoading = true;

    login(data)
      .then(({ token, user, message }) => {
        this.isLoading = false;

        if (token && user) {
          localStorage.setItem('auth_token', token);
          window.location.replace('/dashboard');
        } else {
          toast.error(message);
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  @action setAdminInfo() {
    getLoggedAdminUser()
      .then(({ status, data }) => {
        if (status === 200) {
          const adminInfo = {
            ...data,
            role: {
              ..._.omit(data.roles[0], 'permission_groups'),
              permissions: data.roles[0].permission_groups.map(({ id }) => id),
            },
          };
          this.adminInfo = _.omit(adminInfo, 'roles');
        } else {
          this.logout();
        }
      })
      .catch(() => {
        this.logout();
      });
  }

  @action logout() {
    localStorage.removeItem('auth_token');
    window.location.replace('/login');
  }

  @computed get isLoggedIn() {
    return !!localStorage.getItem('auth_token');
  }

  @computed get isAgencyLead() {
    return this.adminInfo.role.id === RolesEnum.AGENCY_LEAD;
  }

  @computed get isAdmin() {
    return this.adminInfo.role.id === RolesEnum.ADMIN;
  }

  @computed get isTeamLead() {
    return this.adminInfo.role.id === RolesEnum.TEAM_LEAD;
  }
}

export default new Auth();
