// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import keys from 'lodash/keys';
import { RecipientFilters, SelectedFakeUser, MultiMessage } from 'src/types/messages';
import { GenderEnum } from 'src/types/enums';
import formatFilterDate from 'src/utils/formatFilterDate';
import { getRecipients } from 'src/endpoints/users';
import { sendMultiMessages } from 'src/endpoints/messages/multi';

const initialMessage: MultiMessage = {
  subject: '',
  message: '',
  image: '',
  sendAtDate: '',
  sendAtTime: '',
  openChat: '1',
};

class MultiMessages {
  @observable isSendingMessage = false;

  @observable isRecipientsLoading = false;

  @observable recipientFilters: Partial<RecipientFilters> = { age: '0-0', send_with_email: '1' };

  @observable recipientCount;

  @observable gender = GenderEnum.NONE;

  @observable selectedFakeUser: Partial<SelectedFakeUser> = {};

  @observable messages = [{ ...initialMessage }];

  @action loadRecipients() {
    this.isRecipientsLoading = true;
    const formData = new FormData();
    if (this.recipientFilters.age === '0-0') {
      this.isRecipientsLoading = false;
      toast.error('Invalid age range.');
      return;
    }
    keys(this.recipientFilters).forEach((key) => {
      if (key === 'campaign_ids') return;
      formData.append(`${key}`, this.recipientFilters[key]);
    });
    if (this.gender === 1 || this.gender === 2) {
      formData.append('gender', this.gender.toString());
    }
    getRecipients(formData)
      .then((res) => {
        this.recipientCount = res.data.potential_chats_count || 0;
        this.isRecipientsLoading = false;
      })
      .catch(() => {
        // toast.error(JSON.stringify(err));
        this.isRecipientsLoading = false;
      });
  }

  @action setRecipientFilters(key: keyof RecipientFilters, value) {
    this.recipientFilters[key] = value;
  }

  @action resetRecipientFilters() {
    this.recipientFilters = {};
    this.gender = GenderEnum.NONE;
    this.loadRecipients();
  }

  @action setGender(gender) {
    this.gender = gender;
  }

  @action setRegisterDate({ startDate, endDate }) {
    if (startDate !== endDate) {
      this.setRecipientFilters(
        'registerDate',
        `${formatFilterDate(startDate)}_${formatFilterDate(endDate)}`
      );
    }
  }

  @action setSelectedFakeUser(fakeUser: SelectedFakeUser) {
    if (fakeUser) {
      this.selectedFakeUser = fakeUser;
      this.recipientFilters.user_id = fakeUser.value;
    } else {
      this.selectedFakeUser = {};
      this.recipientFilters.user_id = null;
    }
    this.loadRecipients();
  }

  @action addTab() {
    this.messages = [...this.messages, { ...initialMessage }];
  }

  @action closeTab(index) {
    const newMessages = [...this.messages];
    newMessages.splice(index, 1);
    this.messages = newMessages;
  }

  @action updateMessage(messageIndex, key: keyof MultiMessage, value) {
    const newMessages = [...this.messages];
    newMessages[messageIndex][key] = value;
    this.messages = newMessages;
  }

  @action sendMessages() {
    this.isSendingMessage = true;
    if (this.recipientFilters.age === '0-0') {
      this.isSendingMessage = false;
      toast.error('Invalid age range.');
      return;
    }
    const formData = new FormData();
    this.messages.forEach(({ subject, message, image, sendAtDate, sendAtTime, openChat }, i) => {
      formData.append(`messages[${i + 1}][subject]`, subject);
      formData.append(`messages[${i + 1}][message]`, message);
      formData.append(`messages[${i + 1}][open_chat]`, openChat);
      formData.append(
        `messages[${i + 1}][send_at]`,
        `${sendAtDate || dayjs().format('YYYY-MM-DD')} ${sendAtTime || '00:00'}:00`
      );
      if (image) {
        formData.append(`messages[${i + 1}][image]`, image);
      }
    });
    keys(this.recipientFilters).forEach((key) => {
      if (key === 'campaign_ids') return;
      formData.append(`filters[${key}]`, this.recipientFilters[key]);
    });
    if (this.gender === 1 || this.gender === 2) {
      formData.append('filters[gender]', this.gender.toString());
    }
    if (this.recipientFilters.campaign_ids) {
      this.recipientFilters.campaign_ids.forEach((c, i) => {
        formData.append(`filters[campaign_ids][${i}]`, c);
      });
    }

    formData.append('sender_type', '2');
    formData.append('sender_id', this.selectedFakeUser.value || '');

    sendMultiMessages(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Messages sent!');
          this.resetState();
          this.loadRecipients();
          this.addTab();
        } else {
          toast.error('Failed to send messages.');
        }
      })
      .finally(() => {
        this.isSendingMessage = false;
      });
  }

  @action resetState() {
    this.recipientFilters = { send_with_email: '1' };
    this.selectedFakeUser = {};
    this.gender = GenderEnum.NONE;
    this.messages = [];
  }
}

export default new MultiMessages();
