// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { action, observable, runInAction } from 'mobx';
import { getDashboardInfo, getModDashboardInfo } from 'src/endpoints/dashboard';
import {
  getASAMessages,
  getChatMessages,
  getOnlineMods,
  getOpenMessage,
} from 'src/endpoints/rooms/rooms';
import modsStore from 'src/stores/Mods';
import AuthStore from 'src/stores/Auth';
import RolesEnum from 'src/types/roles';

class Dashboard {
  @observable isLoading = false;

  @observable dashboardData = {} as any;

  @observable onlineMods = [] as any;

  @observable asaMessages = [] as any;

  @observable openMessages = [] as any;

  @observable metaOpen: any;

  @observable metaAsa: any;

  @observable currentChatMessages = [] as any;

  @observable meta: any;

  @observable modsReady = false;

  setDashboardData = (data) => {
    this.dashboardData = data;
  };

  updateDashboardData = (key, value) => {
    this.dashboardData[key] = value;
  };

  setOnlineMods = (data) => {
    this.onlineMods = data;
  };

  setAsaMessages = (data) => {
    this.asaMessages = data;
  };

  setOpenMessages = (data) => {
    this.openMessages = data;
  };

  @action getRoomTime = (type) => {
    if (type === 'open') {
      return modsStore.modTimeOpen;
    }
    return modsStore.modTimeASA;
  };

  updateOnlineMods = (data) => {
    if (!this.onlineMods.some((room) => room.id === data.id)) {
      this.onlineMods.push({ ...data });
    } else {
      this.onlineMods = this.onlineMods.map((room) =>
        String(room.id) === String(data.id) ? data : room
      );
    }
  };

  updateAsaMessages = (data) => {
    if (this.asaMessages && !this.asaMessages.some((item) => item.id === data.id)) {
      this.asaMessages.push({ ...data });
    } else {
      this.asaMessages = this.asaMessages.map((item) =>
        String(item.id) === String(data.id) ? data : item
      );
    }
  };

  updateOpenMessages = (data) => {
    if (this.openMessages && !this.openMessages.some((item) => item.id === data.id)) {
      this.openMessages.push({ ...data });
    } else {
      this.openMessages = this.openMessages.map((item) =>
        String(item.id) === String(data.id) ? data : item
      );
    }
  };

  @action async loadPaginatedAsa(page, controller) {
    try {
      const { data, meta } = await getASAMessages({ page }, controller);
      this.asaMessages = data;
      this.metaAsa = meta;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action async loadPaginatedOpen(page, controller) {
    try {
      const { data, meta } = await getOpenMessage({ page }, controller);
      this.openMessages = data;
      this.metaOpen = meta;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  removeMod = (record) => {
    this.onlineMods = this.onlineMods.filter((item) => item.id !== record.id);
  };

  setChatMessages = (data) => {
    this.currentChatMessages = data;
  };

  @action getDashboardInfo() {
    this.isLoading = true;

    const getInfo = [RolesEnum.TEAM_LEAD, RolesEnum.AGENCY_LEAD].includes(
      AuthStore.adminInfo.role.id
    )
      ? getModDashboardInfo
      : getDashboardInfo;
    getInfo()
      .then(({ data }) => {
        this.isLoading = false;
        this.setDashboardData(data);
      })
      .catch(() => {
        // toast.error(err.toString());
        this.isLoading = false;
      });
  }

  @action handleDashboardUpdate(updateData) {
    this.isLoading = true;
    Object.keys(updateData).forEach((key) => {
      const value = updateData[key];
      if (key === 'onlineMod') {
        this.updateOnlineMods(value);
      } else if (key === 'modLogout') {
        this.removeMod(value);
      } else {
        this.updateDashboardData(key, value);
      }
    });
    this.isLoading = false;
  }

  @action clearOnlineMods() {
    this.setOnlineMods([]);
  }

  @action getOnlineModsDashboard() {
    getOnlineMods({})
      .then((res) => {
        runInAction(() => {
          this.modsReady = true;
          this.onlineMods = res.data;
          this.meta = res.meta;
        });
      })
      .catch(() => {
        // toast.error(err.toString());
      });
  }

  @action loadPaginated(page) {
    getOnlineMods({ page })
      .then((res) => {
        runInAction(() => {
          this.modsReady = true;
          this.onlineMods = res.data;
          this.meta = res.meta;
        });
      })
      .catch(() => {
        // toast.error(err.toString());
      });
  }

  @action getModsChat(chatId) {
    getChatMessages(chatId, {}).then(({ data }) => {
      this.setChatMessages(data);
    });
  }

  @action checkForEnd(seconds, id, roomType) {
    if (this.getRoomTime(roomType) < seconds) {
      this.removeMod({ id });
    }
  }
}

export default new Dashboard();
