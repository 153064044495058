export enum UserMediaTypeEnum {
  ALL = 'all',
  PHOTO = 'photo',
  VIDEO = 'video',
}

export enum UserMediaVisibilityEnum {
  ALL = 'all',
  PRIVATE = '0',
  PUBLIC = '1',
}

export enum UserMediaStatusEnum {
  ALL = 'all',
  APPROVED = 'approved',
  PENDING = 'pending',
  DELETED = 'deleted',
}

export interface UserMediaFilters {
  userId: string;
  type: UserMediaTypeEnum;
  visibility: UserMediaVisibilityEnum;
  status: UserMediaStatusEnum;
}
