// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  getUsers,
  deleteUser,
  verifyUser,
  deleteAndBanEmailOrIP,
  getUsersStats,
} from 'src/endpoints/users';
import formatFilterDate from 'src/utils/formatFilterDate';
import omitEmptyValues from 'src/utils/omitEmptyValues';
import { GenderEnum } from 'src/types/enums';
import { UserFilters } from 'src/types/users';

class Users {
  @observable isLoading = false;

  @observable isLoadingStats = false;

  @observable userStats = <any>{};

  @observable users = [];

  @observable selectedUsers = [];

  @observable filters: Partial<UserFilters> = {};

  @observable meta: any;

  @action async load(load = true) {
    if (load) {
      this.isLoading = true;
    }
    try {
      const { data, meta } = await getUsers({
        type: 2,
        ...omitEmptyValues(this.filters),
      });
      this.users = data;
      this.meta = meta;
      this.isLoading = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoading = false;
    }
  }

  @action async loadStats() {
    this.isLoadingStats = true;
    try {
      const { data } = await getUsersStats({
        type: 2,
        ...omitEmptyValues(this.filters),
      });
      this.userStats = data;
      this.isLoadingStats = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoadingStats = false;
    }
  }

  @action async loadPaginated(showLoader = true, page) {
    if (showLoader) {
      this.isLoading = true;
    }
    try {
      const { data, meta } = await getUsers({
        type: 2,
        ...omitEmptyValues(this.filters),
        page,
      });
      this.users = data;
      this.meta = meta;
      this.isLoading = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoading = false;
    }
  }

  @action async verify(id): Promise<any> {
    return verifyUser(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('User verified.');
          this.load();
        } else {
          toast.error('Failed to verify user.');
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action async delete(id): Promise<any> {
    return deleteUser(id)
      .then((res) => {
        if (res.status === 200) {
          toast.success('User deleted.');
          this.load(false);
          this.loadStats();
        } else {
          toast.error('Failed to delete user.');
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action async deleteAndBan(id, type) {
    try {
      await deleteAndBanEmailOrIP(id, type);
      await this.load();
    } catch (err) {
      console.log(JSON.stringify(err));
    }
  }

  @action setFilter(key: keyof UserFilters, value: any) {
    this.filters[key] = value;
  }

  @action resetFilters() {
    this.filters = <UserFilters>{};
    this.load();
  }

  @action resetFiltersOnly() {
    this.filters = <UserFilters>{};
  }

  @action setRegisteredDateFilter(selection) {
    if (selection.startDate !== selection.endDate) {
      this.filters.registerDate = `${formatFilterDate(selection.startDate)}_${formatFilterDate(
        selection.endDate
      )}`;
    }
  }

  usersByGender(gender) {
    return (
      this.users.length &&
      _.filter(this.users, (user) => (user as any).gender === GenderEnum[gender.toUpperCase()])
        .length
    );
  }

  @computed get hasFilters() {
    return !_.isEmpty(this.filters);
  }
}

export default new Users();
