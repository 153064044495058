import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CRow, CCol } from '@coreui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStores } from 'src/stores';
import Loader from 'src/components/Loader';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const Login = React.lazy(() => import('./views/Login/Login'));
const AppLayout = React.lazy(() => import('./components/AppLayout'));
const AuthVerify = React.lazy(() => import('./components/AuthVerify'));

const App = observer(() => {
  const { authStore } = useStores();

  useEffect(() => {
    if (window.location.pathname !== '/login' && !authStore.adminInfo.role?.permissions.length) {
      authStore.setAdminInfo();
    }
  }, []);

  if (window.location.pathname !== '/login' && !authStore.adminInfo.role?.permissions.length) {
    return (
      <CRow style={{ height: '100vh', alignContent: 'center' }}>
        <CCol>
          <Loader />
        </CCol>
      </CRow>
    );
  }

  return (
    <>
      <Router>
        <React.Suspense fallback={loading}>
          <AuthVerify logout={authStore.logout} />
          <Switch>
            <Route path="/login" name="Login" render={(props) => <Login {...props} />} />
            <Route path="/" name="Home" render={(props) => <AppLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
      <ToastContainer autoClose={3000} theme="colored" />
    </>
  );
});

export default App;
