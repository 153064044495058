// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { getDeletedUsers, restoreProfile } from 'src/endpoints/users';

class DeletedUsers {
  @observable deletedUsers = [];

  @observable isLoading = false;

  @observable search = '';

  @observable sortBy = 'deleted_at,desc';

  @observable meta: any;

  @action async setSearch(a) {
    this.search = a;
  }

  @action async setSortBy(a) {
    this.sortBy = a;
  }

  @action async getPaginated(page, username) {
    this.isLoading = true;
    try {
      const isEmail = username.includes('@');
      const { data, meta } = await getDeletedUsers(
        username
          ? isEmail
            ? {
                page,
                email: username,
              }
            : {
                page,
                username,
              }
          : { page }
      );
      this.deletedUsers = data;
      this.meta = meta;
      this.isLoading = false;
    } catch (err) {
      // toast.error(JSON.stringify(err));
      this.isLoading = false;
    }
  }

  @action async restoreUser(id) {
    await restoreProfile(id);
    this.getPaginated(1, this.search);
  }
}

export default new DeletedUsers();
