// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { getMultiAccounts, banUser, deleteUser } from 'src/endpoints/users';

class MultiAccounts {
  @observable isLoading = false;

  @observable multiAccounts = [];

  @observable selectedIps = [];

  @observable meta: any;

  @action async loadPaginated(page = 1) {
    this.isLoading = true;

    try {
      const {
        data: { data, per_page, current_page, total },
      } = await getMultiAccounts({ page });
      this.multiAccounts = data;
      this.meta = { per_page, current_page, total };
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  @action async banAccount(ids: Array<string | number>) {
    await banUser(ids);
    this.loadPaginated(this.meta.page);
  }

  @action async deleteAccount(id) {
    await deleteUser(id);
    this.loadPaginated(this.meta.page);
  }

  @action setSelectedIps(ids) {
    this.selectedIps = ids;
  }
}

export default new MultiAccounts();
