import http from '.';

export const getImages = (params): Promise<any> => {
  return http.get('/images', { params }).then((res) => res.data);
};

export const getImagesUser = (params, id): Promise<any> => {
  return http.get(`/images/${id}`, { params }).then((res) => res.data);
};

export const uploadImage = (data): Promise<any> => {
  return http.post('/image', data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const deleteImages = (ids: Array<string>): Promise<any> => {
  return http.post('/image/delete', { image_ids: ids });
};

export const setImagesAsProfile = (userId, imageId): Promise<any> => {
  return http.post('/image/set/profile', { user_id: userId, image_id: imageId });
};

export const updateImageProperty = (imageIds, property, property_value): Promise<any> => {
  return http.post('/image/set/property', { image_ids: imageIds, property, property_value });
};

export interface UploadFromInstagramParams {
  user_id?: string;
  instagram_name?: string;
  count?: string;
}

export const uploadFromInstagram = (params: UploadFromInstagramParams): Promise<any> => {
  return http.post('instagram-images', null, { params }).then((res) => res.data);
};
