// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { getRealImages, getImagesByFilter, deleteImages } from 'src/endpoints/images';
import formatFilterDate from 'src/utils/formatFilterDate';

class Media {
  @observable data = [];

  @observable selectedUsers = [];

  @observable filters: Record<string, any> = {};

  @action async getData() {
    try {
      const { data } = await getRealImages();
      this.data = data.data;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action async getFilteredData() {
    try {
      const { data } = await getImagesByFilter(this.filters);
      this.data = data.data;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action async resetFilters() {
    this.filters = {};
    await this.getData();
  }

  @action setFilter(key, value) {
    this.filters[key] = value;
  }

  @action removeFilter(key) {
    delete this.filters[key];
  }

  // @action setAgeFilter(key, value) {
  //   this.ageFilters[key] = value;
  //   this.filters.age = `${this.ageFilters.from}-${this.ageFilters.to}`;
  // }

  @action setRegisteredDateFilter(selection) {
    if (selection.startDate !== selection.endDate) {
      this.filters.registerDate = `${formatFilterDate(selection.startDate)}_${formatFilterDate(
        selection.endDate
      )}`;
    }
  }

  @action deleteImage(ids) {
    return deleteImages(ids).then((res) => {
      if (res.status === 200) {
        this.getData();
      }
    });
  }
}

export default new Media();
