// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { getBannedIPs, unBanIP } from 'src/endpoints/ips';

class BannedIPs {
  @observable bannedIps = [];

  @observable selectedIps = [];

  @action async getData() {
    try {
      const { data } = await getBannedIPs();
      this.bannedIps = data.data;
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action async unBanIPs(ips) {
    try {
      await unBanIP(ips.map(({ ip_address_id }) => ip_address_id));
      this.selectedIps = [];
      await this.getData();
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
  }

  @action setSelectedIps(ids) {
    this.selectedIps = ids;
  }
}

export default new BannedIPs();
