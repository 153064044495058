// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  getUser,
  updateUser,
  changeUserPassword,
  updateAnswers,
  toggleInterest,
  toggleCharacter,
  getUserTransactions,
  getUserPostback,
} from 'src/endpoints/users';
import { User } from 'src/types/users';

class UserProfile {
  @observable isLoading = false;

  @observable userData: Record<keyof User, any> = <User>{};

  @observable conversationCount = 0;

  @observable updatedUserData: Record<string, any> = {};

  @observable userCharacterTraits = [];

  @observable userInterests = [];

  @observable answersToUpdate: Record<1 | 2, Array<Record<any, any>>> = {
    1: [],
    2: [],
  };

  @observable generalQuestions: Array<Record<string, any>> = [];

  @observable idealPartnerQuestions: Array<Record<string, any>> = [];

  @observable filters = {};

  @observable transactions: Array<Record<string, any>> = [];

  @observable transactionMeta: any;

  @observable postback: Array<Record<string, any>> = [];

  @observable postbackMeta: any;

  @action async getUserData(id, showLoader = false) {
    if (showLoader) {
      this.isLoading = true;
    }

    try {
      const { data } = await getUser(id);
      this.userData = data;
      this.updatedUserData = { ...this.userData };
      this.userCharacterTraits = data.characters;
      this.userInterests = data.interests;

      const selectedAnswerMapping = (question) => {
        const answer = data.answers[question.id];
        if (answer) {
          const multiAnswers = answer.map(({ answer_id }) => answer_id);
          if (multiAnswers.length > 1) {
            question.selectedAnswer = multiAnswers;
          } else if (answer[0].text_answer) {
            question.selectedAnswer = answer[0].text_answer;
          } else if (answer[0].answer_id) {
            question.selectedAnswer = answer[0].answer_id;
          }
        }

        return question;
      };

      this.generalQuestions =
        data.questions[1] &&
        data.questions[1].sort((a, b) => a.order - b.order).map(selectedAnswerMapping);
      this.idealPartnerQuestions =
        data.questions[2] &&
        data.questions[2].sort((a, b) => a.order - b.order).map(selectedAnswerMapping);
    } catch (err) {
      // toast.error(JSON.stringify(err));
    }
    this.isLoading = false;
  }

  @action async updateUser() {
    const fields = [
      'first_name',
      'last_name',
      'username',
      'email',
      'birthday',
      'country_id',
      'city',
      'postal_code',
      'latitude',
      'longitude',
      'gender',
      'biography',
      'quote',
      'admin_info',
    ];
    return updateUser(this.userData.id, _.pick(this.updatedUserData, fields))
      .then((res) => {
        if (res.status === 200) {
          toast.success('User updated.');
          this.getUserData(this.userData.id);
        } else {
          toast.error('Failed to update user.');
        }
      })
      .catch((err) => toast(JSON.stringify(err)));
  }

  @action async changePassword(newPassword) {
    return changeUserPassword(this.userData.id, newPassword)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Password changed.');
        }
      })
      .catch((err) => toast(JSON.stringify(err)));
  }

  @action setAnswersToUpdate(section: 1 | 2, field: 'answer' | 'answer_id' | 'answer_ids', data) {
    const question = this.answersToUpdate[section].find(
      ({ question_id }) => question_id === data.question_id
    );

    if (question) {
      question[field] = data[field];
    } else {
      this.answersToUpdate[section].push(data);
    }
  }

  @action setAnswersToUpdateMulti(
    section: 1 | 2,
    field: 'answer' | 'answer_id' | 'answer_ids',
    data
  ) {
    const question = this.answersToUpdate[section].find(
      ({ question_id }) => question_id === data.question_id
    );
    const finalArray = data.answer_id.map(({ value }) => value);
    const multiField = { question_id: data.question_id, answer_ids: finalArray };
    if (question) {
      question[field] = multiField[field];
    } else {
      this.answersToUpdate[section].push(multiField);
    }
  }

  @action resetAnswersToUpdate(section: 1 | 2) {
    this.answersToUpdate[section] = [];
  }

  @action updateConversationCount(count) {
    this.conversationCount = count;
  }

  @action async updateUserAnswers(section: 1 | 2) {
    const data = {
      section,
      question_answers: this.answersToUpdate[section],
    };

    return updateAnswers(this.userData.id, data)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Answers updated.');
          this.getUserData(this.userData.id);
          this.resetAnswersToUpdate(section);
        } else {
          toast.error('Failed to update answers.');
        }
      })
      .catch((err) => toast(JSON.stringify(err)));
  }

  @action async toggleUserInterest(interestId) {
    return toggleInterest(this.userData.id, interestId)
      .then((res) => {
        if (res.status === 200) {
          this.getUserData(this.userData.id);
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action async toggleUserCharacter(characterId) {
    return toggleCharacter(this.userData.id, characterId)
      .then((res) => {
        if (res.status === 200) {
          this.getUserData(this.userData.id);
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action setUserData(key: keyof User, value: any) {
    this.updatedUserData = { ...this.updatedUserData, [key]: value };
  }

  @action loadUserTransactions(page) {
    return getUserTransactions(this.userData.id, page)
      .then((res) => {
        if (res.status === 200) {
          this.transactions = res.data.data;
          this.transactionMeta = res.data.meta;
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action loadUserPostback(page) {
    return getUserPostback(this.userData.id, page)
      .then((res) => {
        if (res.status === 200) {
          this.postback = res.data.data;
          this.postbackMeta = res.data.meta;
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }
}

export default new UserProfile();
