const config = {
  apiUrl: process.env.API_URL,
  landingUrl: process.env.LANDING_URL,
  hereLocationUrl: process.env.HERE_LOCATION_URL,
  hereLocationApiKey: process.env.HERE_LOCATION_API_KEY,
  nextPublicBackendUrl: process.env.NEXT_PUBLIC_BACKEND_URL || '',
  nextPublicPusherApiKey: process.env.NEXT_PUBLIC_PUSHER_API_KEY || '',
  nextPublicPusherAppCluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER || '',
};

export default config;
