import http from '../index';

export const getMods = async (data): Promise<any> => {
  return http.get('/mods', { params: data }).then((res) => res.data);
};

export const getModsController = async (data, controller): Promise<any> => {
  return http.get('/mods', { params: data, signal: controller.signal }).then((res) => res.data);
};

export const getOnlineMods = async (data): Promise<any> => {
  return http.get('/mods/online', { params: { data } }).then((res) => res.data);
};

export const getBannedMods = async (page): Promise<any> => {
  return http.get('/mods/blocked', { params: { page } }).then((res) => res);
};

export const getMod = async (id): Promise<any> => {
  return http.get(`/mods/${id}`).then((res) => res.data);
};

export const createMod = async (data): Promise<any> => {
  return http.post('/mods', data);
};

export const deleteMod = async (id): Promise<any> => {
  return http.delete(`/mods/${id}`);
};

export const updateMod = async (id, data): Promise<any> => {
  return http.put(`/mods/${id}`, data);
};

export const changeModPassword = async (id, data): Promise<any> => {
  return http.put(`/mods/${id}`, data);
};

export const banMod = async (id, data): Promise<any> => {
  return http.post(`/mods/${id}/block`, data);
};

export const unbanMod = async (id): Promise<any> => {
  return http.delete(`/mods/${id}/unblock`);
};

export const kickMod = async (id): Promise<any> => {
  return http.put(`/mods/${id}/kick`);
};

export const logoutMod = async (id): Promise<any> => {
  return http.post(`/mods/logout/${id}`);
};

export const getEarningReport = (id, data): Promise<any> => {
  return http.get(`/earning-reports/${id}`, data).then((res) => res.data);
};

export const getEarningReports = (params): Promise<any> => {
  return http.get('/earning-reports', { params }).then((res) => res.data);
};

export const createEarningReports = (data): Promise<any> => {
  return http.post('/earning-reports', data).then((res) => res);
};
