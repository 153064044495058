/**
 * Converts date from react-date-range component
 * to date format used in BE filtering
 * @param {*} date
 * @returns YYYY-MM-DD
 */
const formatFilterDate = (date) => {
  const d = new Date(date);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
};

export default formatFilterDate;
