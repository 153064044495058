// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action, computed } from 'mobx';
import {
  getMods,
  createMod,
  deleteMod,
  logoutMod,
  banMod,
  unbanMod,
  getBannedMods,
} from 'src/endpoints/mods/mods';
import { getModSettings } from 'src/endpoints/mods/settings';
import omitEmptyValues from 'src/utils/omitEmptyValues';

interface TimeActivities {
  periods: [];
  username: string;
  time_online: number;
}

interface ModSettings {
  id: number;
  min_chars_asa: number;
  min_chars_open: number;
  time_out_asa: number;
  time_out_open: number;
}

class Mods {
  @observable modsData: Array<Record<string, any>> = [];

  @observable bannedModsData = [];

  @observable earningsReport = [];

  @observable timeActivities: TimeActivities[] = [];

  @observable filter = '';

  @observable meta: any;

  @observable modsMeta: any;

  @observable modSettings: ModSettings | Record<any, any> = {};

  @action load() {
    return getMods({})
      .then((res) => {
        this.modsData = res.data;
        this.modsMeta = res.meta;
      })
      .catch(() => {
        // toast.error(JSON.stringify(err));
      });
  }

  @action loadPaginated(data) {
    return getMods(omitEmptyValues(data))
      .then((res) => {
        this.modsData = res.data;
        this.modsMeta = res.meta;
      })
      .catch(() => {
        // toast.error(JSON.stringify(err));
      });
  }

  @action loadBanned(page) {
    return getBannedMods(page)
      .then((res) => {
        if (res.status === 200) {
          this.bannedModsData = res.data.data;
          this.meta = res.data.meta;
        }
      })
      .catch(() => {
        // toast.error(JSON.stringify(err));
      });
  }

  @action create(data) {
    return createMod(data).then((res) => {
      if (res.status === 201) {
        this.load();
      }
    });
  }

  @action delete(id) {
    return deleteMod(id).then((res) => {
      if (res.status === 200) {
        this.load();
      }
    });
  }

  @action ban(id, data) {
    return banMod(id, data).then((res) => {
      if (res.status === 200) {
        this.load();
      }
    });
  }

  @action unban(id) {
    return unbanMod(id).then((res) => {
      if (res.status === 200) {
        this.loadBanned(1);
      }
    });
  }

  @action logout(id) {
    return logoutMod(id).then((res) => {
      if (res.status === 200) {
        this.load();
      }
    });
  }

  @action setFilter(value) {
    this.filter = value;
  }

  @action async getModSettingsAction() {
    await getModSettings()
      .then(({ data }) => {
        this.modSettings = {
          id: data.id,
          min_chars_asa: data.min_chars_asa,
          min_chars_open: data.min_chars_open,
          time_out_asa: data.time_out_asa,
          time_out_open: data.time_out_open,
        };
      })
      .catch(() => {
        // toast.error(JSON.stringify(err));
      });
  }

  @computed get modTimeASA() {
    return this.modSettings && this.modSettings.time_out_asa * 60;
  }

  @computed get modTimeOpen() {
    return this.modSettings && this.modSettings.time_out_open * 60;
  }

  @computed get mods() {
    const regex = new RegExp(this.filter, 'gi');
    return this.modsData.filter((mod) => mod.username.match(regex));
  }

  @computed get bannedMods() {
    return this.modsData.filter((mod) => mod.banned);
  }
}

export default new Mods();
