// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { observable, action } from 'mobx';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import {
  getProfileQuestions,
  createProfileQuestion,
  updateProfileQuestion,
  deleteProfileQuestion,
  createProfileAnswer,
  updateProfileAnswer,
  deleteProfileAnswer,
} from 'src/endpoints/settings/profileQuestions';
import { ProfileQuestion } from 'src/types/profileQuestions';

class ProfileQuestions {
  @observable isLoading = false;

  @observable generalQuestions: Array<ProfileQuestion> = [];

  @observable idealPartnerQuestions: Array<ProfileQuestion> = [];

  @observable newQuestion: Record<string, any> = {
    question_type_id: 2,
    gender: 0,
  };

  @observable questionsForEdit = {};

  @observable answersForEdit = {};

  @observable newAnswers = {};

  @action load(showLoader = false) {
    if (showLoader) {
      this.isLoading = true;
    }

    return getProfileQuestions().then((res) => {
      if (res.status === 200) {
        this.generalQuestions = res.data['1']
          ? res.data['1'].sort((a, b) => a.order - b.order)
          : [];
        this.idealPartnerQuestions = res.data['2'] ? res.data['2'] : [];
      } else {
        toast.error('Failed to load profile questions.');
      }
      this.isLoading = false;
    });
  }

  @action setNewQuestion(key, value) {
    this.newQuestion = {
      ...this.newQuestion,
      [key]: value,
    };
  }

  @action setQuestionForEdit(id, key, value) {
    const questionForEdit: Record<string, any> =
      [...this.generalQuestions, ...this.idealPartnerQuestions].find(
        ({ id: questionId }) => questionId === id
      ) || {};
    this.questionsForEdit = {
      ...this.questionsForEdit,
      [id]: {
        ...questionForEdit,
        ...this.questionsForEdit[id],
        [key]: value,
      },
    };
  }

  @action createQuestion(section: 1 | 2) {
    return createProfileQuestion({
      ...this.newQuestion,
      section,
      order: this[section === 1 ? 'generalQuestions' : 'idealParnetQuestions']?.length || 1,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success('New question created.');
          this.newQuestion = {
            question_type_id: 2,
            gender: 0,
          };
          this.load();
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action updateQuestion(id) {
    const question = {
      ..._.omit(this.questionsForEdit[id], ['id', 'type', 'answers']),
      question_type_id:
        this.questionsForEdit[id].question_type_id ||
        (this.questionsForEdit[id].type === 'text' ? 1 : 2),
    };

    return updateProfileQuestion(id, question)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Question updated.');
          this.questionsForEdit = {
            ...this.questionsForEdit,
            [id]: null,
          };
          this.load();
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action onSortEnd({ section, oldIndex, newIndex }) {
    this[section] = arrayMoveImmutable(this[section], oldIndex, newIndex);
    this.updateProfileQuestionOrder(this[section]).then(() => this.load());
  }

  @action updateProfileQuestionOrder(questions) {
    const updatedQuestions = questions.map((q, index) => {
      q.order = index;
      return _.pick(q, ['id', 'question', 'question_type_id', 'section', 'order']);
    });

    return Promise.all(updatedQuestions.map((q) => updateProfileQuestion(q.id, q)));
  }

  @action deleteQuestion(id) {
    return deleteProfileQuestion(id).then((res) => {
      if (res.status === 200) {
        toast.success('Question deleted.');
        this.load();
      }
    });
  }

  @action setNewAnswer(questionId, answer) {
    this.newAnswers = {
      ...this.newAnswers,
      [questionId]: {
        question_id: questionId,
        answer,
      },
    };
  }

  @action setAnswerForEdit(id, value) {
    this.answersForEdit = {
      ...this.answersForEdit,
      [id]: { answer: value },
    };
  }

  @action createAnswer(questionId) {
    return createProfileAnswer(this.newAnswers[questionId])
      .then((res) => {
        if (res.status === 200) {
          toast.success('Answer created.');
          this.newAnswers = {
            ...this.newAnswers,
            [questionId]: null,
          };
          this.load();
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action updateAnswer(id) {
    return updateProfileAnswer(id, this.answersForEdit[id])
      .then((res) => {
        if (res.status === 200) {
          toast.success('Answer updated.');
          this.answersForEdit = {
            ...this.answersForEdit,
            [id]: null,
          };
          this.load();
        }
      })
      .catch((err) => console.log(JSON.stringify(err)));
  }

  @action deleteAnswer(id) {
    return deleteProfileAnswer(id).then((res) => {
      if (res.status === 200) {
        toast.success('Answer deleted.');
        this.load();
      }
    });
  }
}

export default new ProfileQuestions();
